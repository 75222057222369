import { render, staticRenderFns } from "./SsoUserCard.vue?vue&type=template&id=a047a622&"
import script from "./SsoUserCard.vue?vue&type=script&lang=js&"
export * from "./SsoUserCard.vue?vue&type=script&lang=js&"
import style0 from "./SsoUserCard.vue?vue&type=style&index=0&id=a047a622&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports